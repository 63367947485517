<template>
  <div class="navHolder">
    <div class="bg" @click="closeMenu" @keyup.enter="closeMenu" v-if="menuOpen" />
    <div
      @focus="openMenu"
      @focusout="closeMenu"
      @mouseleave="closeMenu"
      @mouseover="openMenu"
      class="nav routerLinks"
      :class="{ open: menuOpen, hiddenOnDesktop: hideSidenav }"
    >
      <div class="plantSearch">
        <enactSearch2
          light
          :categoryOverrides="['BmPlant', 'NonBmPlant']"
          v-if="$store.state.ui.selectedEnactView === 'Enact' && $store.state.ui.menuOpen"
          @select="goToPlantPage" />
      </div>
      <div class="routes niceScroll">
        <div class="linkContainer" v-for="(route, link) in pagesToShow" :key="link">
          <div class="header" v-if="route.hasOwnProperty('sectionName')">
            <div class="link animate" @click="headerSelect(link)" @keyup.enter="headerSelect(link)">
              <span>
                <font-awesome-icon
                  :icon="[route.solid ? 'fas' : 'fad', route.icon]"
                  class="iconLink"
                  :title="route.name"
                  :aria-label="route.name"
                />
                <template v-if="$store.state.ui.menuOpen">
                  <span class="textLink header">
                    {{ route.sectionName }}
                    <i class="iconHeader icofont-rounded-down" />
                  </span>
                </template>
              </span>
            </div>
            <div v-if="link === headerSelected" class="subPageHolder">
              <div class="linkContainer" v-for="(page, pageLink) in route.pages" :key="pageLink">
                <sideNavPage :route="page" :link="pageLink" :iconType="page.solid ? 'fas' : 'fad'" indent />
              </div>
            </div>
          </div>
          <sideNavPage v-else :route="route" :link="link" :iconType="route.solid ? 'fas' : 'fad'" @click="headerSelect(null)" />
        </div>
      </div>
      <sideNavBottom class="bottom" v-if="$store.state.ui.menuOpen" />
    </div>
    <div class="nav routerLinks" style="margin-bottom: 5px;" v-if="!loggedIn">
      <div class="routes" style="overflow-y: hidden">
        <div class="linkContainer bottomNav" style="cursor: pointer;" v-for="(nav, key) in bottomNav" :key="key">
          <sideNavPage
            :route="nav"
            clickable
            :iconType="nav.iconType"
            :url="nav.url"
            free
          />
        </div>
      </div>
      <getInTouch @close="toggleGetInTouch" v-if="showGetInTouch" />
    </div>
  </div>
</template>

<script>

import logoIcon from '@/assets/imgs/enact-logo-icon.png';
import sideNavPage from '@/components/sideNav/sideNavPage';
import sideNavBottom from '@/components/sideNav/sideNavBottom';
import enactSearch2 from '@/components/inputs/enactSearch2';
import getInTouch from '@/components/current/getInTouch';

export default {
    components: {
        sideNavPage,
        sideNavBottom,
        enactSearch2,
        getInTouch,
    },
    data () {
        return {
            showGetInTouch: false,
            logo: logoIcon,
            headerSelected: null,
            bottomNav: [{
                icon: 'question',
                iconType: 'fas',
                name: 'About LCP Enact',
                availableOnMobile: false,
                url: 'https://lcpenact.com',
            }, {
                icon: 'envelope',
                iconType: 'fas',
                name: 'Stay up to date',
                availableOnMobile: false,
                link: '',
                click: () => this.toggleGetInTouch(),
            }, {
                icon: 'twitter',
                iconType: 'fab',
                availableOnMobile: false,
                name: 'Twitter',
                url: 'https://twitter.com/lcpenergy',
            }, {
                icon: 'linkedin',
                iconType: 'fab',
                availableOnMobile: false,
                name: 'LinkedIn',
                url: 'https://linkedin.com/company/lcp-energy',
            },
            ],
            routes: {
                Enact: {
                    color: '50, 51, 116',
                    options: {
                        current: {
                            solid: true,
                            icon: 'bolt',
                            name: 'The Current',
                            availableOnMobile: true,
                        },
                        leaderboard: {
                            sectionName: 'Plant Tracking',
                            icon: 'trophy',
                            pages: {
                                leaderboard: {
                                    icon: 'trophy',
                                    name: 'Leaderboard',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                                'gb-index': {
                                    icon: 'chart-line',
                                    name: 'Index',
                                    availableOnMobile: false,
                                    countries: ['Gb'],
                                },
                                index: {
                                    icon: 'chart-line',
                                    name: 'European Index',
                                    availableOnMobile: false,
                                },
                            },
                        },
                        'monthly-summary': {
                            icon: 'newspaper',
                            name: 'Monthly Summary',
                            availableOnMobile: true,
                            countries: ['Gb'],
                        },
                        dashboard: {
                            icon: 'columns',
                            name: 'User Dashboard',
                            availableOnMobile: true,
                        },
                        boa: {
                            sectionName: 'Balancing',
                            icon: 'arrow-alt-square-up',
                            pages: {
                                'boa-dashboard': {
                                    icon: 'arrow-alt-square-up',
                                    name: 'BOA Dashboard',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                                'boa-comparer': {
                                    icon: 'layer-group',
                                    name: 'BOA Comparer',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                            },
                        },
                        plants: {
                            sectionName: 'Plant Analysis',
                            icon: 'lightbulb-on',
                            pages: {
                                'bingo-card': {
                                    icon: 'th',
                                    name: 'Bingo Board',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                                plantActivityBoard: {
                                    icon: 'list',
                                    name: 'Plant Activity',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                                'remit-summary': {
                                    icon: 'brake-warning',
                                    name: 'Unavailability',
                                    availableOnMobile: true,
                                },
                                'storage-da-analysis': {
                                    icon: 'chart-bar',
                                    name: 'Storage DA Analysis',
                                    availableOnMobile: false,
                                    countries: ['Gb'],
                                },
                                'ffr-analysis': {
                                    icon: 'chart-scatter',
                                    name: 'FFR Analysis',
                                    availableOnMobile: false,
                                    countries: ['Gb'],
                                },
                            },
                        },
                        'epex-grid': {
                            icon: 'list',
                            name: 'EPEX Grid',
                            availableOnMobile: true,
                            countries: ['Gb'],
                        },
                        'weather-forecasting': {
                            sectionName: 'Weather Forecasts',
                            icon: 'sun-cloud',
                            pages: {
                                'weather-forecasting': {
                                    icon: 'sun-cloud',
                                    name: 'Weather Forecasts',
                                    availableOnMobile: false,
                                    countries: ['Gb'],
                                },
                                'wind-forecasting': {
                                    icon: 'wind-turbine',
                                    name: 'Wind Forecasts',
                                    availableOnMobile: false,
                                    countries: ['Gb'],
                                },
                            },
                        },
                        map: {
                            sectionName: 'Maps',
                            icon: 'globe-europe',
                            pages: {
                                'map-page': {
                                    icon: 'map-marked-alt',
                                    name: 'Zonal Map',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                                'bingo-map': {
                                    icon: 'map-pin',
                                    name: 'Bingo Map',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                                'zonal-build-up': {
                                    icon: 'map-marked-alt',
                                    name: 'Zonal Build-Up',
                                    availableOnMobile: false,
                                    countries: ['Gb'],
                                },
                                'europe-map-page': {
                                    icon: 'globe-europe',
                                    name: 'Europe Map',
                                    availableOnMobile: true,
                                },
                            },
                        },
                        'historic-analysis': {
                            icon: 'search',
                            name: 'Historical Analysis',
                            availableOnMobile: false,
                        },
                        'forecast-history': {
                            icon: 'history',
                            name: 'History of Forecasts',
                            availableOnMobile: false,
                        },
                        marketInfo: {
                            sectionName: 'Market Information',
                            icon: 'info-circle',
                            pages: {
                                'plant-table': {
                                    icon: 'table',
                                    name: 'Plant Table',
                                    availableOnMobile: true,
                                },
                                'ancillary-contracts': {
                                    icon: 'file-signature',
                                    name: 'Ancillary Contracts',
                                    countries: ['Gb'],
                                },
                                news: {
                                    icon: 'rss',
                                    name: 'News',
                                    availableOnMobile: true,
                                    countries: ['Gb'],
                                },
                            },
                        },
                        notifications: {
                            icon: 'bell-on',
                            name: 'Notifications',
                            availableOnMobile: true,
                        },
                        'bess-carbon-emissions-calculator': {
                            icon: 'calculator',
                            name: 'Carbon Emissions Calculator',
                            availableOnMobile: false,
                        },
                    },
                },
                EnVision: {
                    color: '189, 56, 97',
                    options: {
                        'market-reports': {
                            icon: 'newspaper',
                            name: 'Market Reports',
                            availableOnMobile: true,
                        },
                        'asset-reports': {
                            icon: 'newspaper',
                            name: 'Asset Reports',
                            availableOnMobile: true,
                        },
                        dashboard: {
                            icon: 'columns',
                            name: 'User Dashboard',
                            availableOnMobile: true,
                        },
                        'forecast-comparison': {
                            icon: 'search',
                            name: 'Forecast Comparison',
                            availableOnMobile: false,
                        },
                    },
                },
            },
            defaultAccess: {
                hasAccess: false,
                accessRequired: 'Enact Visualise',
            },
            dashboardShareOpen: false,
            dashboardSaveOpen: false,
            sharePopoutOpen: false,
        };
    },
    computed: {
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
        selectedEnact () {
            return this.$store.state.ui.selectedEnactView;
        },
        menuOpen () {
            return this.$store.state.ui.menuOpen;
        },
        burgerClass () {
            return this.$store.state.ui.menuOpen === true ? 'is-active' : '';
        },
        pageName () {
            return this.$store.state.ui.pageName;
        },
        hasNotifications () {
            const access = this.$store.state.ui.pageAccessLevels.notifications;
            return access == null ? this.defaultAccess : access;
        },
        unseenNotifications () {
            const tableData = this.$store.state.liveTable.storeTables;
            return tableData.hasOwnProperty('Notifications') ? tableData.Notifications.data.filter(
                (o) => this.notificationIsUnseen(o),
            ).length : 0;
        },
        userSettings () {
            return this.$store.state.user;
        },
        defaultCountry () {
            return this.$store.state.user.country;
        },
        pagesToShow () {
            const routesToFilter = this.routes[this.selectedEnact].options;
            const pagesToShow = {};
            Object.keys(routesToFilter).forEach(headerRouteKey => {
                const headerRouteValue = routesToFilter[headerRouteKey];
                const hasSub = headerRouteValue.hasOwnProperty('pages');
                if (hasSub) {
                    const subPages = {};
                    Object.keys(headerRouteValue.pages).forEach(subRouteKey => {
                        const subRouteValue = headerRouteValue.pages[subRouteKey];
                        if (this.showPage(subRouteValue)) subPages[subRouteKey] = subRouteValue;
                    });
                    if (Object.keys(subPages).length > 0) {
                        const clone = { ...headerRouteValue };
                        clone.pages = subPages;
                        pagesToShow[headerRouteKey] = clone;
                    }
                } else {
                    if (this.showPage(headerRouteValue)) pagesToShow[headerRouteKey] = headerRouteValue;
                }
            });
            return pagesToShow;
        },
        hideSidenav () {
            return !this.userSettings.sideNavVisible;
        },
    },
    methods: {
        showPage (page) {
            return !page.countries || page.countries.includes(this.defaultCountry);
        },
        toggleGetInTouch () {
            this.showGetInTouch = !this.showGetInTouch;
        },
        openMenu () {
            this.$store.commit('openMenu');
        },
        closeMenu () {
            this.headerSelected = null;
            this.$store.commit('closeMenu');
        },
        notificationIsUnseen (notification) {
            if (notification.row.Unseen.hasOwnProperty('value')) return notification.row.Unseen.value === 'False';

            if (notification.row.Unseen.hasOwnProperty('values')) {
                return notification.row.Unseen.values[0] === 'False';
            }
            return false;
        },
        goToPlantPage (plant) {
            this.$router.push({ path: `/plant/${plant.id}` });
        },
        headerSelect (header) {
            if (this.headerSelected === header) {
                this.headerSelected = null;
            } else {
                this.headerSelected = header;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/components/sideNav/sideNav.scss';
.linkContainer {
    background: var(--headerColour);
    &.bottomNav {
        // padding-top: 5px;
        // padding-bottom: 5px;
    }
}
.bg {
    background: rgba(0,0,0,.4);
    bottom:0;
    left:0;
    position: fixed;
    right:0;
    top:0;
    z-index:12;
    animation: fadeIn $modal-transition linear;
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
.menuActivator {
    // background:  var(--mid);
    display: inline-block;
    height: 50px;
    padding: 5px;
    .hamburger {
        padding: 5px;
        outline: 0;
        .mobileNotifications{
            position: absolute;
            width:10px;
            height:10px;
            border-radius:50%;
            top: -5px;
            right: -5px;
            background: $BRIGHT;
            @include responsive("smUp"){
                display:none;
            }
        }
    }
    &.disabled {
        .hamburger {
            cursor: default;
            .hamburger-inner {
                background-color: grey;
                cursor: default;
                &::before,
                &::after {
                    background-color: grey;
                }
            }
        }
    }
}
.routerLinks {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: auto;
}
.navHolder {
    height: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    color: white;

    .nav {
        background: var(--headerColour);
        margin: 10px 0 0 0;
        border-radius: 0 $border-radius $border-radius 0;
        box-shadow: 0 2px 2px 0px var(--shadowColour);
        padding-top: 0px;
        width: 50px;
        z-index: 13;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: all .3s ease-in-out;

        @include responsive('md'){
            width: 0px;
        }

        &.hiddenOnDesktop {
            width: 0px;
        }

        &.open {
            width: 250px;
        }
        .plantSearch {
            padding: 10px;
            background:var(--mid);
            @include responsive('mdUp'){
                display: none;
            }
        }
        .bottom {
            // height: 100px;
            overflow-y: hidden;
        }
        .routes {
            // flex-grow: 1;
            overflow-x: hidden;
        }
        .header {
            cursor: pointer;
            .subPageHolder {
                .linkContainer {
                    background: rgba(0,0,0, 0.3);
                }
            }
        }
    }
}
.dark {
    .navHolder {
        .nav {
            .header {
                .subPageHolder {
                    .linkContainer {
                        background: var(--mid);
                    }
                }
            }
        }
    }

}
</style>
